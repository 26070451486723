import axios from "axios";


export function introduceDelayInCreation() {
//
  // Function logic for introducing delay in employee creation
  axios.get("https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/create/true").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Introducing delay in employee creation');
}

export function introduceDelayInUpdate() {
  // Function logic for introducing delay in employee updation
  axios.get("https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/update/true").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Introducing delay in employee updation');
}

export function revertCreationDelay() {
  // Function logic for reverting employee creation delay
  axios.get("https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/create/false").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee creation delay');
}

export function revertUpdateDelay() {
  // Function logic for reverting employee updation delay
  axios.get("https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/update/false").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee updation delay');
}

// New 

export function IntroduceSalaryMiddleWareDelay() {
  // Function logic for reverting employee updation delay
  axios.post("https://hr-demo-app.mainservice.development.us-east.kabali.dev/actuator/chaosmonkey/enable/salarymiddleware").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee updation delay');
}

export function revertSalaryMiddlewareDelay() {
  // Function logic for reverting employee updation delay
  axios.post("https://hr-demo-app.mainservice.development.us-east.kabali.dev/actuator/chaosmonkey/disable/salarymiddleware").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee updation delay');
}

export function IntroduceSalaryDelay() {
  // Function logic for reverting employee updation delay
  axios.post("https://hr-demo-app.mainservice.development.us-east.kabali.dev/actuator/chaosmonkey/enable/salaryservice").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee updation delay');
}

export function revertSalaryDelay() {
  // Function logic for reverting employee updation delay
  axios.post("https://hr-demo-app.mainservice.development.us-east.kabali.dev/actuator/chaosmonkey/disable/salaryservice").then(response => {
    console.log(response.data);
  })
      .catch(error => {
        console.error('Error response:', error.response);
        console.error('Error message:', error.message);
      });
  console.log('Reverting employee updation delay');
}
  
  
