import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Row } from 'antd';

export default function Editemp({ empId, closeEdit }) {
  const [emp, setemp] = useState({
    name: "",
    sex: "",
    dateOfBirth: "",
    department: "",
    role: "",
  });

  const { name, sex, dateOfBirth, department, role } = emp;

  const onInputChange = (e) => {
    setemp({ ...emp, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (empId) {
      loadempl(empId);
    }
  }, [empId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/${empId}`, emp);
      closeEdit(true); // Notify the parent that the edit was successful
    } catch (error) {
      console.error("There was an error updating the employee!", error);
    }
  };

  const loadempl = async (id) => {
    try {
      const result = await axios.get(`https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/${id}`);
      setemp(result.data);
    } catch (error) {
      console.error("There was an error loading the employee!", error);
    }
  };

  return (
    <Row justify='center'>
      <div style={{ position: 'absolute', top: '10%', left: '0', width: '100%' }}>
        <div className="row">
          <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow" style={{ background: 'white' }}>
            <h2 className="text-center m-4">Update Employee</h2>
            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name"
                  name="name"
                  value={name}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sex" className="form-label">Sex</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your gender (Male, Female)"
                  name="sex"
                  value={sex}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your DOB (mm-dd-yyyy)"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="department" className="form-label">Department</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your department"
                  name="department"
                  value={department}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your role"
                  name="role"
                  value={role}
                  onChange={onInputChange}
                />
              </div>
              <button type="submit" className="btn btn-outline-primary">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => closeEdit(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </Row>
  );
}
