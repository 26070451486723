import axios from "axios";
import React, { useState } from "react";
import { Row } from 'antd';

const AddUser = ({ handleClose }) => {
  const [emp, setEmp] = useState({
    name: "",
    sex: "",
    dateOfBirth: "",
    department: "",
    role: "",
  });

  const { name, sex, dateOfBirth, department, role } = emp;

  const onInputChange = (e) => {
    setEmp({ ...emp, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!name || !sex || !dateOfBirth || !department || !role) {
      alert("All fields are mandatory. Please fill in all the details.");
      return;
    }

    // Validation checks for specific fields
    const validSexValues = ["Male", "Female"];
    if (!validSexValues.includes(sex)) {
      alert("Invalid value for gender. Allowed values are Male or Female.");
      return;
    }

    const isValidDate = (dateString) => {
      const pattern = /^\d{2}-\d{2}-\d{4}$/;
      if (!pattern.test(dateString)) return false;
      const dateParts = dateString.split("-");
      const day = parseInt(dateParts[1]);
      const month = parseInt(dateParts[0]);
      const year = parseInt(dateParts[2]);
      if (year < 1000 || year > 9999 || month == 0 || month > 12) return false;
      const daysInMonth = new Date(year, month, 0).getDate();
      return day > 0 && day <= daysInMonth;
    };

    if (!isValidDate(dateOfBirth)) {
      alert("Invalid Date of Birth. Use the format mm-dd-yyyy.");
      return;
    }

    const validDepartments = ["Admin", "Development", "HR", "Support"];
    if (!validDepartments.includes(department)) {
      alert("Invalid Department. Allowed values are Admin, Development, HR, or Support.");
      return;
    }

    const validRoles = ["SE", "SSE", "TL", "Architect"];
    if (!validRoles.includes(role)) {
      alert("Invalid Role. Allowed values are SE, SSE, TL, or Architect.");
      return;
    }

    // If all validation checks pass, send the POST request to add the employee
    await axios.post("https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/create-employees", emp);
    handleClose(true); // Notify parent that the employee was added
  };

  return (
    <Row justify='center'>
      <div style={{ position: 'absolute', top: '10%', left: '0', width: '100%' }}>
        <div className="row">
          <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow" style={{ background: 'white' }}>
            <h2 className="text-center m-4">Register Employee</h2>

            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name"
                  name="name"
                  value={name}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sex" className="form-label">Sex</label>
                <select
                  className="form-select"
                  name="sex"
                  value={sex}
                  onChange={onInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your DOB (mm-dd-yyyy)"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="department" className="form-label">Department</label>
                <select
                  className="form-select"
                  name="department"
                  value={department}
                  onChange={onInputChange}
                >
                  <option value="">Select Department</option>
                  <option value="Admin">Admin</option>
                  <option value="Development">Development</option>
                  <option value="HR">HR</option>
                  <option value="Support">Support</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">Role</label>
                <select
                  className="form-select"
                  name="role"
                  value={role}
                  onChange={onInputChange}
                >
                  <option value="">Select Role</option>
                  <option value="SE">SE</option>
                  <option value="SSE">SSE</option>
                  <option value="TL">TL</option>
                  <option value="Architect">Architect</option>
                </select>
              </div>
              <button type="submit" className="btn btn-outline-primary">Submit</button>
              <button type="button" className="btn btn-outline-danger mx-2" onClick={() => handleClose(false)}>Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default AddUser;
