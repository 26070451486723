import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row } from 'antd';

export default function ViewEmployee({ empId, closeView }) {
  const [employee, setEmployee] = useState({
    empNo: "",
    name: "",
    sex: "",
    dateOfBirth: "",
    department: "",
    role: "",
  });

  console.log('empId', empId);

  useEffect(() => {
    loadEmployee(empId);
  }, [empId]);

  const loadEmployee = async (id) => {
    const result = await axios.get(`https://hr-demo-app.mainservice.development.us-east.kabali.dev/api/v1/${id}`);
    setEmployee(result.data);
  };

  return (
    <Row justify='center'>
      <div style={{ position: 'absolute', top: '10%', left: '0', width: '100%' }}>
        <div className="row">
          <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow" style={{ background: 'white' }}>
            <h2 className="text-center m-4">Employee Details</h2>

            <div className="card">
              <div className="card-header">
                <b>Details of employee id:</b>  {employee.empNo}
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Name:</b> {employee.name}
                  </li>
                  <li className="list-group-item">
                    <b>Sex:</b> {employee.sex}
                  </li>
                  <li className="list-group-item">
                    <b>Date of Birth:</b> {employee.dateOfBirth}
                  </li>
                  <li className="list-group-item">
                    <b>Department:</b> {employee.department}
                  </li>
                  <li className="list-group-item">
                    <b>Role:</b> {employee.role}
                  </li>
                </ul>
              </div>
            </div>
            <button className="btn btn-outline-primary" onClick={() => { closeView(false) }}>
              Back to UI
            </button>
          </div>
        </div>
      </div>
    </Row>
  );
}


