import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './navbar.css';
import {
  introduceDelayInCreation,
  introduceDelayInUpdate,
  revertCreationDelay,
  revertUpdateDelay,
  IntroduceSalaryMiddleWareDelay,
  revertSalaryMiddlewareDelay,
  IntroduceSalaryDelay,
  revertSalaryDelay,
} from './delay.js';
import AddUser from '../emp/Addemp.js';

export default function Navbar({ refreshEmpList }) {
  const [showAddUser, setShowAddUser] = useState(false);
  const [buttonColor, setButtonColor] = useState('black');

  const handleClick = () => {
    setShowAddUser(true);
  };

  const handleClose = (updated) => {
    setShowAddUser(false);
    if (updated) {
      refreshEmpList(true);
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <div className='container-fluid'>
            <DropdownButton id="dropdown-item-button" title="Introduce Delays">
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  introduceDelayInCreation(() => setButtonColor('danger'));
                }}
              >
                Introduce delay in employee creation
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  introduceDelayInUpdate(() => setButtonColor('danger'));
                }}
              >
                Introduce delay in employee updation
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  revertCreationDelay(() => setButtonColor('success'));
                }}
              >
                Revert employee creation delay
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  revertUpdateDelay(() => setButtonColor('success'));
                }}
              >
                Revert employee updation delay
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  IntroduceSalaryMiddleWareDelay(() => setButtonColor('success'));
                }}
              >
                Create delay in salary middleware service
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  revertSalaryMiddlewareDelay(() => setButtonColor('success'));
                }}
              >
                Revert delay in salary middleware service
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  IntroduceSalaryDelay(() => setButtonColor('success'));
                }}
              >
                Create delay in salary service
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className={`btn btn-${buttonColor}`}
                onClick={() => {
                  revertSalaryDelay(() => setButtonColor('success'));
                }}
              >
                Revert delay in salary service
              </Dropdown.Item>
            </DropdownButton>

            <p style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'center', paddingTop: '19px' }}>EMPLOYEE MANAGEMENT DEMO-APP</p>

            <button className="btn btn-outline-light add-emp-btn" onClick={handleClick}>Add Employee</button>
          </div>
        </nav>
      </div>
      {showAddUser && <AddUser handleClose={handleClose} />}
    </>
  );
}
